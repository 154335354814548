import * as Cookies from "js-cookie";
import { handleAccountLogin } from "./gaEvents";
import { cookieSetter } from "./hospitalCookie";
import { globalDef } from "./siteDefinitions";

/**
 * Validate the username on account creation or edit
 */
export function validateUsername(username: string) {
    return new Promise<{ valid: boolean }>((resolve, reject) => {
        $.ajax({
            url: `${globalDef.ajaxFunctionsUrl}function=validateUsername`,
            method: "POST",
            data: { username },
            complete({ responseText }) {
                resolve(JSON.parse(responseText));
            },
            error(error) {
                reject(error);
            },
        });
    });
}

/**
 * Enable login for front end users.
 */
export function login() {
    let loginEnabled = true;
    if (!loginEnabled) {
        return;
    }
    loginEnabled = false;
    const loginError = document.getElementById("login_error") as HTMLInputElement;
    const u = (document.querySelector("input[name=Username]") as HTMLInputElement).value;
    const p = (document.querySelector("input[name=User_Password]") as HTMLInputElement).value;
    const m = globalDef.noSlash;

    loginError.textContent = "Please wait while you are logged in...";
    loginError.style.display = "block";

    loginAjax(u, p, m)
        .then((response) => {
            if (response.result.trim() === "Login successful") {
                Cookies.set(response.cookie.name, response.cookie.value, {
                    expires: 1,
                    path: "/",
                    domain: globalDef.cmnDomain,
                    secure: true,
                });
                cookieSetter(response.hospital);
                loginEnabled = true;
                window.location.href = `${globalDef.mainDomain}your-account/`;
                handleAccountLogin();
            } else {
                // Display error
                loginEnabled = true;
                loginError.textContent = response.result;
            }
        })
        .catch(() => {
            // Display error
            loginEnabled = true;
            loginError.value = "Error Logging In";
        });
}

function loginAjax(u: string, p: string, m: string) {
    return new Promise<InterfaceLogin>((resolve, reject) => {
        $.ajax({
            url: `${globalDef.cmnhAjaxFunctionsUrl}function=login`,
            cache: false,
            type: "post",
            data: {
                user: u,
                pass: p,
                mainDomain: m,
            },
            complete(response) {
                const resText = response.responseText.replace(/\r?\n|\r/g, "");
                const resJSON = JSON.parse(resText);
                resolve(resJSON);
            },
            error() {
                reject();
            },
        });
    });
}
