import { globalDef } from "./siteDefinitions";

/**
 * Detect if the hospital changer has been opened
 */
export function hospitalFloaterEvent(change: string) {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "hospital-changer",
        eventAction: change,
        eventLabel: "Hospital Picker Click"
    });
}

/**
 * If a new hospital selected change the action
 */
export function hospitalSelected(cookie: string) {
    if (!globalDef.gaIncluded) {
        return false;
    }

    if (!cookie) {
        ga("send", {
            hitType: "event",
            eventCategory: "hospital-changer",
            eventAction: "initial-change",
            eventLabel: "Hospital Initial Picker Changed"
        });
    } else {
        ga("send", {
            hitType: "event",
            eventCategory: "hospital-changer",
            eventAction: "changed-set",
            eventLabel: "Hospital Picker Actual Changed"
        });
    }
}

/**
 * On first time visit send event
 */
export function hospitalSet() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "hospital-changer",
        eventAction: "initial-set",
        eventLabel: "Hospital Initial Set"
    });
}

/**
 * Send event once the video has loaded
 */
export function handleVideoLoad() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Videos",
        eventAction: "loaded",
        eventLabel: "Hospital Floating Video"
    });
}

/**
 * Send event if the video is closed
 */
export function handleVideoClosed(mannerClosed: string) {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Videos",
        eventAction: mannerClosed,
        eventLabel: "Hospital Floating Video"
    });
}

/**
 * Send event if the video is unmuted
 */
export function handleVideoUnmute(actionTaken: string) {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Videos",
        eventAction: actionTaken,
        eventLabel: "Hospital Floating Video"
    });
}

/**
 * Send the browser font size, to see if people be changing it
 */
// export function handleFontSize() {
//     ga("send", {
//         hitType: "event",
//         eventCategory: "Accessibility",
//         eventAction: "Font Size",
//         eventLabel: "Font Size",
//         dimension4: `${globalDef.fontSize}`
//     });
// }

/**
 * Send event if account is logged in
 */
export function handleAccountLogin() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Account",
        eventAction: "Account Access",
        eventLabel: "Sign In"
    });
}

/**
 * Send event if account dropdown opened
 */
export function handleAccountWrapOpen() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Top Menu",
        eventAction: "Open",
        eventLabel: "Account"
    });
}

/**
 * Send event if account dropdown closed
 */
export function handleAccountWrapClose() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Top Menu",
        eventAction: "Close",
        eventLabel: "Account"
    });
}

/**
 * Send event if search dropdown opened
 */
export function handleSearchOpen() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Top Menu",
        eventAction: "Open",
        eventLabel: "Search"
    });
}

/**
 * Send event if search dropdown closed
 */
export function handleSearchClosed() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Top Menu",
        eventAction: "Close",
        eventLabel: "Search"
    });
}

/**
 * Send event if fundraiser is created
 */
export function handleCreateFundraiser() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Fundraisers",
        eventAction: "Create",
        eventLabel: "Created Fundraiser"
    });
}

/**
 * Send event if fundraiser is edited
 */
export function handleEditFundraiser() {
    if (!globalDef.gaIncluded) {
        return false;
    }

    ga("send", {
        hitType: "event",
        eventCategory: "Fundraisers",
        eventAction: "Edit",
        eventLabel: "Edited Fundraiser"
    });
}

/**
 * Send event if fundraiser is ended
 */
export function handleEndFundraiser() {
    if (!globalDef.gaIncluded) {
        return false;
    }
    
    ga("send", {
        hitType: "event",
        eventCategory: "Fundraisers",
        eventAction: "End",
        eventLabel: "Ended Fundraiser"
    });
}
