export class LazyLoad {
    constructor(private lazyImages: NodeListOf<HTMLDivElement | HTMLImageElement>, private type = "image") {
        this.observer();
    }

    private async observer() {
        if (!("IntersectionObserver" in window)) {
            await import(/* webpackChunkName: "intersection-observer" */ "intersection-observer");
        }

        const lazyImageObserver = new IntersectionObserver((entries) => {
            entries.forEach(({ isIntersecting, target }) => {
                if (isIntersecting) {
                    const lazyImage = target as HTMLElement;
                    const imgSrc = lazyImage.dataset.src;

                    if (!imgSrc) return;

                    if (this.type === "image") {
                        (lazyImage as HTMLImageElement).src = imgSrc;
                    } else if (this.type === "div") {
                        lazyImage.style.backgroundImage = `url(${imgSrc})`;
                    }

                    lazyImage.classList.remove("js-lazy");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        for (const lazyImage of this.lazyImages) {
            lazyImageObserver.observe(lazyImage);
        }
    }
}
