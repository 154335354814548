const body = document.body;
const admin = body.classList.contains("wp-admin");
let cmnDebug = 0;
let cmnStaging = 0;
let cmnDev = 0;
let cmnTest = 0;
let cmnWpEngine = 0;

if (!admin && body.dataset.cmnDebug) {
    cmnDebug = parseInt(body.dataset.cmnDebug, 10);
}

if (!admin && body.dataset.cmnStaging) {
    cmnStaging = parseInt(body.dataset.cmnStaging, 10);
} else if (!admin && body.dataset.cmnDev) {
    cmnDev = parseInt(body.dataset.cmnDev, 10);
} else if (!admin && body.dataset.cmnTest) {
    cmnTest = parseInt(body.dataset.cmnTest, 10);
} else if (!admin && body.dataset.cmnWpEngine) {
    cmnWpEngine = parseInt(body.dataset.cmnWpEngine, 10);
}

if (admin && window.location.hostname.includes("cmnlocal.org")) {
    cmnDebug = 1;
}

if (admin && window.location.hostname.includes("staging.childrensmiraclenetworkhospitals.org")) {
    cmnStaging = 1;
}

const windowWidth = 63.9375; // rem 1023px
const smallWidth = 48; // rem 768px

// Staging test credentials
// const noSlash = "https://cmnh.us";
// const cmnDomain = ".cmnh.us";

let noSlash = "https://childrensmiraclenetworkhospitals.org";
let cmnDomain = ".childrensmiraclenetworkhospitals.org";
const ajaxFunctionsUrl = "?ajaxFunctions&";

if (cmnDev) {
    noSlash = "https://dev.childrensmiraclenetworkhospitals.org";
    cmnDomain = ".childrensmiraclenetworkhospitals.org";
} else if (cmnTest) {
    noSlash = "https://test.childrensmiraclenetworkhospitals.org";
    cmnDomain = ".childrensmiraclenetworkhospitals.org";
} else if (cmnStaging) {
    noSlash = "https://staging.childrensmiraclenetworkhospitals.org";
    cmnDomain = ".childrensmiraclenetworkhospitals.org";
} else if (cmnWpEngine) {
    noSlash = "https://cmnhospital.wpengine.com";
    cmnDomain = ".wpengine.com";
} else if (cmnDebug) {
    noSlash = "https://cmnlocal.org";
    cmnDomain = ".cmnlocal.org";
}

const gaIncluded = document.querySelector("script[src*='google-analytics.com/analytics.js']");
const mainDomain = `${noSlash}/`;
const appUrl = window.location.href;
const trimmedAppUrl = appUrl.replace(/(\?.*|#)?/g, "");
const cmnhAjaxFunctionsUrl = `${trimmedAppUrl + ajaxFunctionsUrl}`;
const cmnhJsonAjax = "/wp-content/themes/cmnh/dist/json/";

// Recaptcha site key
const recaptchaSiteKey = cmnDebug ? '6LdqppUpAAAAABr9pEwVL0nu5fL9UDdMB6ZLnTtN' : '6LfoeqIUAAAAAJDliNy2Pfo5AnowXsGTOCpyj_Xp';

/**
 * All the constants for the site. Changes based on production and dev.
 */
export const globalDef = {
    ajaxFunctionsUrl,
    cmnhAjaxFunctionsUrl,
    cmnhJsonAjax,
    mainDomain,
    cmnDomain,
    noSlash,
    cmnDebug,
    cmnStaging,
    windowWidth,
    smallWidth,
    body,
    gaIncluded,
    recaptchaSiteKey,
};
