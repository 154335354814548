import * as tingle from "tingle.js";

export function showVideo(video: HTMLElement) {
    const videoId = video.dataset.videoId as string;

    const viewVideos = new tingle.modal({
        cssClass: ["tingle-full", "video-lightbox"],
        onClose() {
            viewVideos.setContent("");
        },
    });

    viewVideos.setContent(
        `<iframe id='youtube-video' width='800' height='450' src='https://www.youtube.com/embed/${videoId}/?autoplay=1' frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>`
    );

    viewVideos.open();
}
