import { globalDef } from "./siteDefinitions";

/**
 * Strip the scripts. Especially useful for the cookies.
 */
export function stripScripts(s: string) {
    const div = document.createElement("div");
    div.innerHTML = s;
    const scripts = div.getElementsByTagName("script");
    let i = scripts.length;
    while (i--) {
        scripts[i].parentNode!.removeChild(scripts[i]);
    }
    return div.innerHTML;
}

/**
 * Grab the params for a url.
 */
export function getUrlParam(searchParam: string) {
    // Grab a string of all the url parameters
    const params = new URLSearchParams(window.location.search.substring(1));

    if (params.has(searchParam)) {
        // Either return the parameter value or true if value doesn't exist
        return params.get(searchParam) || true;
    }

    return false;
}

export function getAllUrlParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * Make a json call
 */
export function getJson(filePath: string) {
    return new Promise<any>((resolve, reject) => {
        $.getJSON(`${filePath}`, function (data) {
            resolve(data);
        }).fail(function (error) {
            reject(error);
        });
    });
}

/**
 * Show the social media popup.
 */
export function blogPopup(elem: HTMLElement) {
    // Set values for window
    const intWidth = "500";
    const intHeight = "500";

    // Open popup with focus on it
    const strParam = `width=${intWidth},height=${intHeight}`;
    window.open(elem.getAttribute("href") as string, "social_popup", strParam);
}

/**
 * Sets all items in grid to equal height.
 */
export function equalHeight(elems: NodeListOf<HTMLElement>) {
    for (const elem of elems) {
        const gridItemWidth = elem.offsetWidth;

        elem.style.height = `${gridItemWidth}px`;
    }
}

/**
 * Idle callback shim
 */
export function requestIdleCallbackShim() {
    (window as any).requestIdleCallback =
        (window as any).requestIdleCallback ||
        function (cb: any) {
            const start = Date.now();
            return setTimeout(function () {
                cb({
                    didTimeout: false,
                    timeRemaining() {
                        return Math.max(0, 50 - (Date.now() - start));
                    },
                });
            }, 1);
        };
}

export function trimCurrency(value: string) {
    value = value
        .replace(/[^\d\.]/g, "") // eslint-disable-line
        .replace(/\./, "x")
        .replace(/\./g, "")
        .replace(/x/, ".")
        .replace(/,/g, "");

    const decimal = value.indexOf(".");
    if (decimal !== -1) {
        if (value.length > decimal + 3) {
            value = value.substring(0, decimal + 3);
        }
    }

    return value;
}

/**
 * Only allow number to be entered in an input
 */
export function onlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
    }
}

export const mobileWidth = window.matchMedia(`(max-width: ${globalDef.windowWidth}em)`);
export const smallMobileWidth = window.matchMedia(`(max-width: ${globalDef.smallWidth}em)`);
