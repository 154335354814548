import * as Cookies from "js-cookie";
import { hospitalFloaterEvent, hospitalSelected } from "./gaEvents";
import { cookieSetter, setGeoHospital } from "./hospitalCookie";
import { globalDef } from "./siteDefinitions";
import { getUrlParam } from "./utility";

export function hospitalDetectorInit(hospitalDetector: HTMLElement) {
    // The hospital floater is available, so let's show the correct text on it.
    if (document.querySelector(".hospital-floater")) {
        setupHospPickerText();
    }

    // Closes the hospital detector
    document.querySelector(".hospital-detector__close")?.addEventListener("click", closeHospitalDetector);

    document.addEventListener("DOMContentLoaded", hospitalDetectorVisibility);

    const hospitalSearch = hospitalDetector.querySelector(".js-detector-search");

    // Listens to the custom element for the hospitalSelected event
    if (hospitalSearch) {
        hospitalSearch.addEventListener("hospitalSelected", ((event: CustomEvent) => {
            const { hospitalId } = event.detail;

            saveSelected(hospitalId);
        }) as EventListener);

        // Listens to the custom element for the geolocationFound event
        hospitalSearch.addEventListener("geolocationFound", ((event: CustomEvent) => setGeoHospital(event)) as EventListener);
    }
}

export function showHospitalDetector() {
    const hospitalDetector = document.querySelector(".hospital-detector");
    const spinner = document.querySelector<HTMLElement>(".hospital-detector__spinner");
    const page = document.querySelector<HTMLElement>(".hospital-detector-page");
    const search = document.querySelector<any>(".js-detector-search");

    if (!hospitalDetector) {
        return;
    }

    hospitalDetector.setAttribute("open", "");

    if (spinner) {
        spinner.style.display = "none";
    }

    if (page) {
        page.style.display = "block";
    }

    if (search) {
        search.focusSearch();
    }
}

export function homePageHospitalSet(hospitalId: string) {
    const hospitalSection = document.querySelector(".hospital-section");

    if (!hospitalSection) {
        return;
    }

    getHospital(hospitalId).then((hospital) => {
        const hospitalName = hospitalSection.querySelector(".hospital-heading");
        const hospitalText = hospitalSection.querySelector(".hospital-text");
        const hospitalButton = hospitalSection.querySelector("button");
        const hospitalLink = hospitalSection.querySelector("a");

        if (!hospitalName || !hospitalText || !hospitalLink) {
            return;
        }

        hospitalName.textContent = hospital.hospitalName;
        hospitalText.textContent = "is your local member hospital";
        hospitalButton?.classList.add("hospital-section--hidden");
        hospitalLink?.classList.remove("hospital-section--hidden");
        hospitalLink.href = hospital.CmnhWebsite;
    });
}

/**
 * Closes the hospital detector
 *
 * If it's the on the initial load the close button will set the cookie and redirect them
 */
function closeHospitalDetector() {
    const hospitalDetector = document.querySelector(".hospital-detector");

    hospitalDetector?.removeAttribute("open");
}

/**
 * Set up the hospital picking text for the modal.
 */
function setupHospPickerText() {
    const hospIdCookie = Cookies.get("hospital_id");
    const hospFloater = document.querySelector(".hospital-floater");
    const viewingHospID = globalDef.body.dataset.hospitalId as string;

    if (typeof hospIdCookie === "undefined" || hospIdCookie !== viewingHospID) {
        hospFloater!.textContent = "Make this my Hospital";
        hospFloater?.addEventListener("click", () => {
            hospitalFloaterEvent("set");
            cookieSetter(viewingHospID);
            setupHospPickerText();
        });
    } else if (hospIdCookie === viewingHospID) {
        hospFloater!.textContent = "Change Your Hospital";
        hospFloater?.addEventListener("click", () => {
            hospitalFloaterEvent("change");
            showHospitalDetector();
        });
    }
}

/**
 * Will set the hospital cookie and redirect them to their new hospital
 */
function saveSelected(hospitalId: string) {
    const parsedHospitalId = parseInt(hospitalId);
    const redirectHospitalId = marketChangeRedirect(parsedHospitalId);
    if (redirectHospitalId !== parsedHospitalId) {
        hospitalId = redirectHospitalId.toString();
    }
    hospitalSelected(hospitalId);
    cookieSetter(hospitalId);

    // We only want to redirect if we're not currently on the home page
    if (document.querySelector(".page-template-home-page")) {
        homePageHospitalSet(hospitalId);
        return closeHospitalDetector();
    }

    location.href = globalDef.cmnhAjaxFunctionsUrl + `function=redirect&hospital_id=${hospitalId}`;
}

function marketChangeRedirect(hospitalId: number) {
    // Redirect to Roanoke Market hospital
    switch (hospitalId) {
        case 5169:
            return 5024;
    }

    return hospitalId;
}

function getHospital(hospitalId: string) {
    return new Promise<InterfaceHospLookup>((resolve) => {
        $.ajax(`${globalDef.cmnhAjaxFunctionsUrl}function=getHospital&id=${hospitalId}`, {
            method: "GET",
            success(response) {
                resolve(JSON.parse(response));
            },
        });
    });
}

/**
 * Decides the visibility for both hospital detector pages.
 */
function hospitalDetectorVisibility() {
    const close = document.querySelector(".hospital-detector__close") as HTMLElement;
    const page = document.querySelector(".hospital-detector-page") as HTMLElement;
    const spinner = document.querySelector(".hospital-detector__spinner") as HTMLElement;
    const hospitalDetector = document.querySelector(".hospital-detector") as any;

    /**
     * Attach click handler to the change hospital cta
     */
    const selectHosp = document.getElementById("selectHosp");
    if (selectHosp) {
        selectHosp.addEventListener("click", () => {
            spinner.style.display = "none";
            page.style.display = "block";
            (document.querySelector(".js-detector-search") as any).focusSearch();
        });
    }

    /**
     * No hospital is found or the user is trying to access the search via the hospital_search query string param
     */
    const hospitalSearch = getUrlParam("hospital_search");
    if (hospitalSearch) {
        hospitalDetector.setAttribute("open", "true");

        spinner.style.display = "none";
        page.style.display = "block";
        close.style.display = "block";

        (document.querySelector(".js-detector-search") as any).focusSearch();
    }
}
