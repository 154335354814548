import { globalDef } from "./siteDefinitions";

/**
 * Grab all the map data and call showMap to place on page.
 */
export async function initMap() {
    try {
        const currentHosp = globalDef.body.dataset.hospitalId as string;
        const hospital = await getHospitalLocation(currentHosp);

        showMap(hospital);

        return new Promise((resolve) => {
            resolve();
        });
    } catch (e) {
        throw new Error(`Unable to load the map. Error: ${e as string}`);
    }
}

function getHospitalLocation(currentHosp: string) {
    return new Promise<InterfaceHospLocation>((resolve, reject) => {
        $.ajax({
            url: `${globalDef.cmnhAjaxFunctionsUrl}function=showMap&currHosp=${currentHosp}`,
            complete(response) {
                const resText = response.responseText.replace(/\r?\n|\r/g, "");
                const resJSON = JSON.parse(resText);
                resolve(resJSON);
            },
            error() {
                reject(false);
            },
        });
    });
}

/**
 * Create the map and make sure the style is correct.
 */
function showMap(hospital: InterfaceHospLocation) {
    const { latitude, longitude, hospitalName } = hospital;
    // Removes most of the labels from the map.
    const customMapType = new google.maps.StyledMapType(
        [
            {
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "water",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "landscape.natural",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
        ],
        {
            name: "Custom Style",
        }
    );
    const customMapTypeId = "custom_style";

    // Puts the hospital latitude and longitude into a variable.
    const hospLatlng = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
    // mapOptions
    const mapOptions = {
        center: hospLatlng,
        zoom: 13,
        disableDoubleClickZoom: true,
        disableDefaultUI: true,
        scrollwheel: false,
        draggable: false,
        mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId],
        },
    };
    // Creates the map and places it in to the map div.
    const map = new google.maps.Map(document.getElementById("map") as HTMLElement, mapOptions);
    map.mapTypes.set(customMapTypeId, customMapType);
    map.setMapTypeId(customMapTypeId);
    // Changes the marker to the blue icon and adds hospital name into infoWindow
    const mapMarker = {
        path:
            "M119.44,0A119.29,119.29,0,0,0,0,119.44c0,23,7.36,45,17.6,63.12L95.44,317.28c4.88,8.8,14.72,13.2,24,13.2a27.85,27.85,0,0,0,24-13.2l77.84-134.64c10.32-18.08,17.6-39.68,17.6-63.12A119.36,119.36,0,0,0,119.44,0Zm0,154.72a43.6,43.6,0,1,1,43.6-43.6A43.7,43.7,0,0,1,119.44,154.72Z",
        fillColor: "#00add6",
        fillOpacity: 1,
        scale: 0.15,
        anchor: new google.maps.Point(125, 325),
        strokeWeight: 0,
    };
    const hospMarker = new google.maps.Marker({
        position: hospLatlng,
        map,
        title: `${hospitalName}`,
        icon: mapMarker,
    });
    const contentString = `<div class=gmap-infowindow><p class=gmap-hospname>${hospitalName}</p></div>`;
    const infoWindow = new google.maps.InfoWindow({
        content: contentString,
    });
    infoWindow.open(map, hospMarker);
    google.maps.event.addDomListener(infoWindow, "domready", function () {
        // Reference to the DIV which receives the contents of the infowindow using jQuery
        const iwOuter = $(".gm-style-iw");
        const iwBackground = iwOuter.parent();
        iwBackground.children().addClass("gm-style-iw-siblings");
    });

    hospMarker.addListener("click", function () {
        openMapExternal(hospitalName, latitude, longitude);
    });
}

/**
 * Click on marker to open popup of hospital.
 */
function openMapExternal(hospitalName: string, latitude: string, longitude: string) {
    const encodedHosp = encodeURIComponent(hospitalName);
    const mapLoc = `https://www.google.com/maps/place/${encodedHosp}/@${latitude},${longitude},13z`;
    const win = window.open(mapLoc, "CMN Hospitals", "height=800,width=1200,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=no");
    if (win) {
        // Browser has allowed it to be opened
        win.focus();
    } else {
        // Browser has blocked it
        alert("Popup has been blocked. Please allow popups and try clicking on the map again.");
    }
}
